import "styles/pages/single-person.scss";

import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Lightbox from "react-image-lightbox";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Divider from "components/Divider";
import Breadcrumps from "components/Breadcrumps";

const SinglePerson = ({ data: { wpZespol } }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const pageData = wpZespol.team;
  const seo = wpZespol.seo;
  const gallery = pageData.certs?.map((item) => item.sourceUrl);

  const breadcrumps_data = [
    {
      name: "Zespół",
      href: "/zespol/",
    },
    {
      name: pageData.fullName,
    },
  ];

  console.log(seo);

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} />
      <Breadcrumps data={breadcrumps_data} />
      <SubpageHeader title={pageData.fullName} />
      <section className="single-person">
        <div className="container">
          <h2>{pageData.pageTitle}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <div className="row">
            <div className="col-lg-4">
              <div
                className="single-person__img"
                style={{ backgroundImage: `url(${pageData.img?.sourceUrl})` }}
                alt=""
              />

              <div className="single-person__btn">
                <Link to="/rezerwacja-wizyt/" className="button button--green">
                  Umów wizytę
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              <div
                className="single-person__content"
                dangerouslySetInnerHTML={{ __html: pageData.desc }}
              />

              {pageData?.mediaGrid && (
                <div className="single-person__media">
                  <Divider marginTop={45} marginBottom={45} />
                  <h3>Występy w mediach – Sprawdź</h3>
                  <div className="row">
                    {pageData?.mediaGrid?.map((item, index) => (
                      <div className="col-sm-5" key={index}>
                        <a
                          href={item?.url}
                          target="_blank"
                          rel="noreferrer nofollow"
                          className="single-person__media-item"
                        >
                          <div
                            className="single-person__media-item-image"
                            style={{
                              backgroundImage: `url('${item?.posterImage?.localFile?.publicURL}')`,
                            }}
                          />
                          <div>
                            <span>{item?.program}</span>
                            <span>{item?.date}</span>
                          </div>
                          <h4>{item?.header}</h4>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <span id="certyfikaty" />
          {pageData.addCerts && (
            <div className="single-person__certs">
              <h2>Moje certyfikaty</h2>
              <Divider marginTop={15} marginBottom={45} />
              <div className="row">
                {pageData.certs?.map((item, index) => (
                  <div className="col-lg-3 col-md-6" key={index}>
                    <div className="single-person__single-cert">
                      <img
                        src={item.sourceUrl}
                        alt=""
                        className="img-fluid"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsLightboxOpen(true);
                          setPhotoIndex(index);
                        }}
                        aria-hidden="true"
                      />
                      <span
                        className="single-person__zoom-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsLightboxOpen(true);
                          setPhotoIndex(index);
                        }}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </Layout>
  );
};

export default SinglePerson;

export const query = graphql`
  query ($id: String!) {
    wpZespol(id: { eq: $id }) {
      id
      team {
        desc
        addCerts
        certs {
          sourceUrl
        }
        fullName
        img {
          sourceUrl
        }
        pageTitle
        position
        secondPosition
        tileDesc
        mediaGrid {
          date
          url
          program
          header
          posterImage {
            localFile {
              publicURL
            }
          }
        }
      }
      uri
      title
      seo {
        metaDesc
        title
      }
    }
  }
`;
